import { createApp, type App as VueApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import axios from 'axios'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Vue3Toastify, { type ToastContainerOptions, type ToastTheme, updateGlobalOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import VueConfetti from 'vue-confetti'
import { useAuthStore } from '@stores/auth'
import { initializeTheme, currentThemeMode } from '@utils/theme'
import { defaultToastOptions } from '@utils/toast-config'
import { initPusher, disconnectPusher } from '@services/pusher-service'

// Import main app styles
import '../styles/app.scss'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faRedoAlt, faSpinner, faCheck, faUser, faKey, faTrash, faPlus, faLock, faHeart, faCalendar, faCalendarCheck, faUsers, faFilter, faChevronUp, faChevronDown, faMobileAlt, faExclamationTriangle, faEdit, faLongArrowAltLeft, faLongArrowAltRight, faSignOutAlt, faHandPeace, faPalette, faBolt } from '@fortawesome/free-solid-svg-icons'
import { faClock, faCalendarDays, faLightbulb, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTag, faInfoCircle } from '@fortawesome/pro-regular-svg-icons'

import App from '@components/App.vue'
import router from '@router/index'
import moment from 'moment-timezone'
import LoadingButton from '@components/LoadingButton.vue'
import Tooltip from '@directives/tooltip-directive'

// Add FontAwesome icons
library.add(
    faTwitter, faFacebookSquare, faInstagram, faTag, faClock, faLightbulb,
    faCheckCircle, faTimes, faRedoAlt, faSpinner, faCheck, faUser, faKey,
    faTrash, faPlus, faLock, faCalendar, faCalendarCheck, faCalendarDays,
    faHeart, faUsers, faFilter, faChevronUp, faChevronDown, faMobileAlt, faExclamationTriangle,
    faEdit, faLongArrowAltLeft, faLongArrowAltRight, faSignOutAlt, faInfoCircle, faHandPeace, faPalette, faBolt
)

// Set default timezone
moment.tz.setDefault(moment.tz.guess())

// Initialize theme
initializeTheme()

// Create Vue app instance with required plugins
const head = createHead()
const pinia = createPinia()
const app: VueApp = createApp(App)

// Register plugins
app.use(head)
app.use(pinia)
app.use(router)
app.use(VueConfetti)
app.use(Tooltip, {
    delay: 100,
    placement: 'top',
    class: 'tooltip-workweek',
    triggers: ['hover'],
    offset: 0
})

// Create the auth store instance
const authStore = useAuthStore()

// Get the initial toast theme based on the user's theme preference
const getToastTheme = (): ToastTheme => {
    const themeMode = localStorage.getItem('workweek-color-scheme')

    if (themeMode === 'light') return 'light'
    if (themeMode === 'dark') return 'dark'
    return 'auto' // For 'auto' or if no preference is set
}

// Initialize Vue3Toastify with the default options and theme
app.use(Vue3Toastify, {
    ...defaultToastOptions,
    theme: getToastTheme(),
} as ToastContainerOptions)

// Register global components
app.component('LoadingButton', LoadingButton)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('VueDatePicker', VueDatePicker)

// Register directives
app.directive('focus', {
    mounted(el: HTMLElement) {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        if (!isMobile) {
            el.focus()
        }
    }
})

// Configure axios defaults
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
axios.defaults.baseURL = `${import.meta.env.VITE_API_URL}/api/`

// Add axios response interceptor for handling token expiration
let isRefreshing = false
let failedQueue: Array<{ resolve: Function; reject: Function }> = []

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(promise => {
        if (error) {
            promise.reject(error)
        } else {
            promise.resolve(token)
        }
    })
    failedQueue = []
}

axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config

        // If the error is due to an expired token (401) and we haven't tried to refresh yet
        if (error.response?.status === 401 && !originalRequest._retry) {
            // Skip token refresh if it's already the refresh token request that failed
            if (originalRequest.url === '/auth/token/refresh') {
                const authStore = useAuthStore()
                authStore.logout()
                router.push({ name: 'Login' })
                return Promise.reject(error)
            }

            if (isRefreshing) {
                // If we're already refreshing, add this request to the queue
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`
                    return axios(originalRequest)
                }).catch(err => {
                    return Promise.reject(err)
                })
            }

            originalRequest._retry = true
            isRefreshing = true

            // Get refresh token from localStorage
            const refreshToken = localStorage.getItem('refreshToken')
            
            if (!refreshToken) {
                processQueue(error, null)
                isRefreshing = false
                const authStore = useAuthStore()
                authStore.logout()
                router.push({ name: 'Login' })
                return Promise.reject(error)
            }

            try {
                // Import authStore to use the refreshToken method
                const authStore = useAuthStore()
                
                // Call the refreshToken method
                const success = await authStore.refreshToken(refreshToken)
                
                if (success) {
                    const newToken = localStorage.getItem('token')
                    // Update authorization header with new token
                    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`
                    
                    // Process queue with new token and retry original request
                    processQueue(null, newToken)
                    return axios(originalRequest)
                } else {
                    // If token refresh failed, reject all queued requests
                    processQueue(error, null)
                    return Promise.reject(error)
                }
            } catch (refreshError) {
                processQueue(refreshError, null)
                return Promise.reject(refreshError)
            } finally {
                isRefreshing = false
            }
        }

        return Promise.reject(error)
    }
)

// Initialize the application
function initializeApp() {
    // Check if there's a token in localStorage
    const token = localStorage.getItem('token')

    // Only try to initialize auth store if there's a token
    const initPromise = token
        ? authStore.initialize().catch(error => {
            console.error('Error initializing auth store:', error)
        })
        : Promise.resolve()

    // Continue with app initialization after auth is handled
    initPromise.then(() => {
        initPusher()
        // Mount the app after initialization
        app.mount('#app')

        // Ensure toast theme is synchronized with app theme after mounting
        // Preserve all other settings by using the defaultToastOptions
        const isDark = document.documentElement.classList.contains('dark')
        updateGlobalOptions({
            ...defaultToastOptions,
            theme: currentThemeMode.value === 'auto' ? 'auto' : (isDark ? 'dark' : 'light')
        })
    })
}

// Start initialization
initializeApp()

// Listen for authentication changes to update Pusher connection
authStore.$subscribe(() => {
    if (authStore.isLogged) {
        initPusher()
    } else {
        disconnectPusher()
    }
})