import Pusher from 'pusher-js'
import { useTasksStore } from '@stores/tasks'
import { type Task } from '@stores/tasks'

// Singleton pattern for Pusher client
let pusherClient: Pusher | null = null

/**
 * Configure and initialize Pusher connection
 * Sets up event listeners for the authenticated user's private channel
 */
export function initPusher(): void {
  disconnectPusher()
  
  const pusherKey = import.meta.env.VITE_PUSHER_APP_KEY
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')

  if (pusherKey && token && userId) {
    pusherClient = new Pusher(pusherKey, {
      cluster: 'eu',
      forceTLS: true,
      authEndpoint: `${import.meta.env.VITE_API_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
        }
      }
    })

    // Set up task event listeners
    setupTaskListeners(pusherClient, userId)
  }
}

/**
 * Disconnect Pusher client if it exists
 */
export function disconnectPusher(): void {
  if (pusherClient) {
    pusherClient.disconnect()
    pusherClient = null
  }
}

/**
 * Get current Pusher client instance
 */
export function getPusherClient(): Pusher | null {
  return pusherClient
}

/**
 * Set up event listeners for tasks
 */
function setupTaskListeners(pusher: Pusher, userId: string): void {
  const channel = pusher.subscribe(`private-Workweek.App.User.${userId}`)
  const tasksStore = useTasksStore()
  
  channel.bind('task.created', (data: { task: Task }) => {
    tasksStore.addTask(data.task)
  })
  
  channel.bind('task.updated', (data: { task: Task }) => {
    tasksStore.updateTask(data.task)
  })
  
  channel.bind('task.deleted', (data: { task: Task }) => {
    tasksStore.removeTask(data.task)
  })
} 