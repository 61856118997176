/**
 * @author: laurent blanes <laurent.blanes@gmail.com>
 * @tutorial: https://hekigan.github.io/vue-directive-tooltip/
 */

/**
 * usage:
 *
 * // basic usage:
 * <div v-tooltip="'my content'">
 * or
 * <div v-tooltip="{content: 'my content'}">
 *
 * // change position of tooltip
 * // options: auto (default) | bottom | top | left | right
 *
 * // change sub-position of tooltip
 * // options: start | end
 *
 * <div v-tooltip.top="{content: 'my content'}">
 *
 * // add custom class
 * <div v-tooltip="{class: 'custom-class', content: 'my content'}">
 *
 * // toggle visibility
 * <div v-tooltip="{visible: false, content: 'my content'}">
 */

import type { App, DirectiveBinding, Plugin, VNode } from 'vue';
import Tooltip from './tooltip';

const BASE_CLASS = 'vue-tooltip';
const POSITIONS = ['auto', 'top', 'bottom', 'left', 'right'] as const;
const SUB_PLACEMENT = ['start', 'end'] as const;

type Position = typeof POSITIONS[number];
type SubPosition = typeof SUB_PLACEMENT[number];
type Placement = Position | `${Position}-${SubPosition}`;
type TooltipTrigger = 'click' | 'hover' | 'focus' | 'manual' | null;

interface TooltipValue {
    content?: string;
    class?: string;
    id?: string;
    html?: HTMLElement | string;
    placement?: string;
    visible?: boolean;
    ref?: string;
    delay?: number;
    offset?: number | string;
}

interface TooltipHTMLElement extends HTMLElement {
    tooltip?: Tooltip;
}

interface TooltipDefaults {
    delay: number;
    offset: number;
    class: string;
}

interface TooltipOptions {
    class: string;
    id: string | null;
    html: boolean;
    htmlContent?: HTMLElement | string | null;
    placement: Placement;
    title: string;
    triggers: TooltipTrigger[];
    fixIosSafari: boolean;
    offset: number;
    delay: number;
}

interface TooltipModifiers {
    notrigger?: boolean;
    manual?: boolean;
    click?: boolean;
    hover?: boolean;
    focus?: boolean;
    ios?: boolean;
}

const DEFAULT_OPTIONS: TooltipDefaults = {
    delay: 200,
    offset: 0,
    class: ''
};

const TooltipDirective: Plugin = {
    install(app: App, installOptions?: Record<string, unknown>) {
        app.directive('tooltip', {
            beforeMount(el: TooltipHTMLElement, binding: DirectiveBinding<TooltipValue>, vnode: VNode) {
                if (installOptions) {
                    Tooltip.defaults(installOptions);
                }
            },
            mounted(el: TooltipHTMLElement, binding: DirectiveBinding<TooltipValue>, vnode: VNode) {
                if (installOptions) {
                    Tooltip.defaults(installOptions);
                }

                const options = filterBindings(binding, vnode);
                el.tooltip = new Tooltip(el, options);

                if (binding.modifiers.notrigger && binding.value?.visible === true) {
                    el.tooltip.show();
                }

                if (binding.value?.visible === false) {
                    el.tooltip.disabled = true;
                }
            },
            updated(el: TooltipHTMLElement, binding: DirectiveBinding<TooltipValue>, vnode: VNode) {
                const oldValue = binding.oldValue === null ? undefined : binding.oldValue;
                if (hasUpdated(binding.value, oldValue)) {
                    update(el, binding, vnode);
                }
            },
            unmounted(el: TooltipHTMLElement) {
                el.tooltip?.destroy();
            }
        });
    }
};

function hasUpdated(value: TooltipValue | string | undefined, oldValue: TooltipValue | string | undefined): boolean {
    if (!value || !oldValue) return false;
    
    if (typeof value === 'string' && typeof oldValue === 'string') {
        return value !== oldValue;
    }
    
    if (isObject(value) && isObject(oldValue)) {
        return Object.keys(value).some(prop => 
            value[prop as keyof TooltipValue] !== oldValue[prop as keyof TooltipValue]
        );
    }
    
    return false;
}

function filterBindings(
    binding: DirectiveBinding<TooltipValue>,
    vnode: VNode
): Partial<TooltipOptions> {
    const defaults = Tooltip.defaults(DEFAULT_OPTIONS);
    const delay = !binding.value?.delay || isNaN(binding.value.delay) 
        ? defaults.delay 
        : binding.value.delay;

    if (binding.value?.ref) {
        const component = vnode.component;
        if (component && component.refs && component.refs[binding.value.ref]) {
            binding.value.html = component.refs[binding.value.ref] as HTMLElement;
        } else {
            console.error(`[Tooltip] no REF element [${binding.value.ref}]`);
        }
    }

    const htmlContent = binding.value?.html || null;
    const content = getContent(binding, vnode);
    
    return {
        class: getClass(binding, defaults),
        id: binding.value?.id || null,
        html: false,
        htmlContent,
        placement: getPlacement(binding) as Placement,
        title: typeof content === 'string' ? content : '',
        triggers: getTriggers(binding),
        fixIosSafari: (binding.modifiers as TooltipModifiers).ios || false,
        offset: Number(binding.value?.offset || defaults.offset),
        delay
    };
}

function getPlacement(binding: DirectiveBinding<TooltipValue>): string {
    const mods = Object.keys(binding.modifiers);
    if (mods.length === 0 && isObject(binding.value) && typeof binding.value?.placement === 'string') {
        return binding.value.placement;
    }

    const head = mods.find(pos => POSITIONS.includes(pos as Position)) || 'bottom';
    const tail = mods.find(pos => SUB_PLACEMENT.includes(pos as SubPosition));

    return tail ? `${head}-${tail}` : head;
}

function getTriggers(binding: DirectiveBinding<TooltipValue>): TooltipTrigger[] {
    const modifiers = binding.modifiers as TooltipModifiers;
    const triggers: TooltipTrigger[] = [];

    if (modifiers.notrigger) {
        return triggers;
    }

    if (modifiers.manual) {
        triggers.push('manual');
    } else {
        if (modifiers.click) triggers.push('click');
        if (modifiers.hover) triggers.push('hover');
        if (modifiers.focus) triggers.push('focus');
        if (triggers.length === 0) {
            triggers.push('hover', 'focus');
        }
    }

    return triggers;
}

function isObject(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && value !== null;
}

function isElement(value: unknown): value is HTMLElement {
    return value instanceof window.Element;
}

function getClass(binding: DirectiveBinding<TooltipValue>, defaults: TooltipDefaults): string {
    if (!binding.value) {
        return BASE_CLASS;
    }
    
    if (isObject(binding.value) && typeof binding.value.class === 'string') {
        return `${BASE_CLASS} ${binding.value.class}`;
    }
    
    if (defaults.class) {
        return `${BASE_CLASS} ${defaults.class}`;
    }
    
    return BASE_CLASS;
}

function getContent(binding: DirectiveBinding<TooltipValue | undefined>, vnode: VNode): string | HTMLElement {
    const value = binding.value;

    if (!value || typeof value !== 'object') {
        return String(value || '');
    }

    if (value.content !== undefined) {
        return String(value.content);
    }

    if (value.id) {
        const element = document.getElementById(value.id);
        if (element) {
            return element;
        }
    }

    if (value.html) {
        if (typeof value.html === 'string') {
            const element = document.getElementById(value.html);
            if (element) {
                return element;
            }
        }
        if (isElement(value.html)) {
            return value.html;
        }
    }

    if (value.ref && vnode.component?.refs) {
        const refElement = vnode.component.refs[value.ref];
        if (isElement(refElement)) {
            return refElement;
        }
    }

    return '';
}

function update(
    el: TooltipHTMLElement,
    binding: DirectiveBinding<TooltipValue>,
    vnode: VNode
): void {
    if (!el.tooltip) return;

    if (typeof binding.value === 'string') {
        el.tooltip.content(binding.value);
        return;
    }

    if (binding.value?.class && binding.value.class.trim() !== el.tooltip.options.class.replace(BASE_CLASS, '').trim()) {
        el.tooltip.class = `${BASE_CLASS} ${binding.value.class.trim()}`;
    }

    el.tooltip.content(getContent(binding, vnode));

    if (!binding.modifiers.notrigger && binding.value?.visible !== undefined) {
        el.tooltip.disabled = !binding.value.visible;
        return;
    }

    if (binding.modifiers.notrigger) {
        el.tooltip.disabled = false;
    }

    const oldVisible = (binding.oldValue as TooltipValue)?.visible;
    const newVisible = binding.value?.visible;

    if (oldVisible !== newVisible && !el.tooltip.disabled) {
        el.tooltip.toggle(!!newVisible);
    }
}

export default TooltipDirective;