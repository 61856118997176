import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@stores/auth'

// Define the meta fields type with proper index signatures
interface RouteMeta {
    auth: boolean;
    [key: string]: unknown;
    [key: number]: unknown;
    [key: symbol]: unknown;
}

// Define route components with proper types
const Home = () => import(/* @vite-ignore */ '@components/Home.vue')
const Login = () => import(/* @vite-ignore */ '@components/Auth/Login.vue')
const Register = () => import(/* @vite-ignore */ '@components/Auth/Register.vue')
const ForgotPassword = () => import(/* @vite-ignore */ '@components/Auth/ForgotPassword.vue')
const ResetPassword = () => import(/* @vite-ignore */ '@components/Auth/ResetPassword.vue')
const TaskList = () => import(/* @vite-ignore */ '@components/Tasks/TaskList.vue')
const Account = () => import(/* @vite-ignore */ '@components/Settings/Account/Account.vue')
const Security = () => import(/* @vite-ignore */ '@components/Settings/Account/Security.vue')
const Appearance = () => import(/* @vite-ignore */ '@components/Settings/Account/Appearance.vue')
const Delete = () => import(/* @vite-ignore */ '@components/Settings/Account/Delete.vue')
const Team = () => import(/* @vite-ignore */ '@components/Settings/Team/Team.vue')
const Invite = () => import(/* @vite-ignore */ '@components/Settings/Team/Invite.vue')
const Leave = () => import(/* @vite-ignore */ '@components/Settings/Team/Leave.vue')

// Define routes with proper typing
const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { auth: false } as RouteMeta
    },
    {
        path: '/settings/account',
        name: 'Account',
        component: Account,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/settings/security',
        name: 'Security',
        component: Security,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/settings/appearance',
        name: 'Appearance',
        component: Appearance,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/settings/account/delete',
        name: 'Delete',
        component: Delete,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/settings/team/',
        name: 'Team',
        component: Team,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/settings/team/invite/:token?',
        name: 'Invite',
        component: Invite,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/settings/team/leave',
        name: 'Leave',
        component: Leave,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/tasks/:year?/:month?/:day?/:filter?',
        name: 'TaskList',
        component: TaskList,
        meta: { auth: true } as RouteMeta
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { auth: false } as RouteMeta
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: { auth: false } as RouteMeta
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { auth: false } as RouteMeta
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: { auth: false } as RouteMeta
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Add scroll behavior for better UX
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        }
        return { top: 0 }
    }
})

// Type-safe navigation guard
router.beforeEach(
    (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        const authStore = useAuthStore()

        // Get the auth requirement from route meta
        const requiresAuth = to.meta?.auth === true

        // Handle routes that require authentication
        if (requiresAuth) {
            // If not logged in, redirect to login
            if (!authStore.isLogged) {
                return next({ name: 'Login' })
            }
            // User is logged in and trying to access a protected route, allow it
            return next()
        }

        // Handle public routes (login, register, home)
        // If user is logged in and tries to access public routes, redirect to tasks
        if (authStore.isLogged && ['Login', 'Register', 'Home'].includes(to.name as string)) {
            return next({ name: 'TaskList' })
        }

        // For all other cases, allow navigation
        return next()
    }
)

export default router 