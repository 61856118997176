import { defineStore } from 'pinia';
import axios from 'axios';
import moment from 'moment-timezone';

// Types
type LabelColor = 'blue' | 'green' | 'orange' | 'red';
export interface Task {
    id: number;
    title: string;
    starts_at: string;
    completed_at: string | null;
    is_completed: boolean;
    created_at: string;
    updated_at: string;
    due_at: string | null;
    label_text: string | null;
    label_color: LabelColor | null;
    user_id: number;
}

interface TasksState {
    tasks: Task[];
    filteredTasks: Task[];
    endpoint: string;
}

interface TaskForm {
    [key: string]: any;
}

interface TaskUpdatePayload {
    task: Task;
    form: TaskForm;
}

interface ApiResponse<T> {
    data: T;
}

// Define the Pinia store with TypeScript
export const useTasksStore = defineStore('tasks', {
    state: (): TasksState => ({
        tasks: [],
        filteredTasks: [],
        endpoint: '/tasks'
    }),

    getters: {
        tasksFilter: (state: TasksState) => (currentFilter: string, startDate: number, endDate: number): Task[] => {
            const startCurrentWeek = moment.utc().startOf('isoWeek').unix();
            const endCurrentWeek = moment.utc().endOf('isoWeek').unix();

            // Check if tasks exist and has items
            if (!state.tasks || state.tasks.length === 0) {
                state.filteredTasks = [];
                return [];
            }
            
            const filtered = state.tasks.filter((task) => {
                const taskStartTime = moment.utc(task.starts_at).unix();
                const taskCompletedTime = task.completed_at ? moment.utc(task.completed_at).unix() : 0;

                if (!task.is_completed) {
                    return (
                        (startDate <= startCurrentWeek && taskStartTime < endDate) ||
                        (taskStartTime > endCurrentWeek && taskStartTime >= startDate && taskStartTime < endDate)
                    );
                }
                return startDate < taskCompletedTime && taskStartTime < endDate;
            });

            state.filteredTasks = filtered;

            if (!filtered.length) {
                return [];
            }

            switch (currentFilter) {
                case 'completed':
                    return filtered.filter(task => task.is_completed);
                case 'active':
                    return filtered.filter(task => !task.is_completed);
                default:
                    return filtered;
            }
        },

        allTasks(state: TasksState): Task[] {
            return state.filteredTasks;
        },

        activeTasks(state: TasksState): Task[] {
            return state.filteredTasks.filter((task) => !task.is_completed);
        },

        completedTasks(state: TasksState): Task[] {
            return state.filteredTasks.filter((task) => task.is_completed);
        },

        timeToChill: (state: TasksState): (_currentFilter: string) => boolean => {
            return (_currentFilter: string): boolean => {
                const filteredTasks = state.filteredTasks;
                const activeTasks = filteredTasks.filter(task => !task.is_completed);
                return (_currentFilter === 'all' && !filteredTasks.length) ||
                    (_currentFilter === 'active' && !activeTasks.length);
            };
        },

        timeToWork: (state: TasksState): (_currentFilter: string) => boolean => {
            return (_currentFilter: string): boolean => {
                const completedTasks = state.filteredTasks.filter(task => task.is_completed);
                return _currentFilter === 'completed' && !completedTasks.length;
            };
        }
    },

    actions: {
        setTasks(tasks: Task[]): void {
            this.tasks = tasks;
        },

        addTask(task: Task): void {
            const taskExists = this.tasks.some(t => t.id === task.id);
            if (!taskExists) {
                this.tasks.push(task);
            }
        },

        updateTask(task: Task): void {
            const index = this.tasks.findIndex(t => t.id === task.id);
            if (index !== -1) {
                this.tasks[index] = task;
            }
        },

        removeTask(task: Task): void {
            const index = this.tasks.findIndex(t => t.id === task.id);
            if (index !== -1) {
                this.tasks.splice(index, 1);
            }
        },

        clearTasks(): void {
            this.tasks = [];
            this.filteredTasks = [];
        },

        async fetchTasksUser(): Promise<void> {
            const { data } = await axios.get<ApiResponse<Task[]>>(this.endpoint);
            this.setTasks(data.data);
        },

        async fetchTasksTeam(data: { userId: number }): Promise<void> {
            const { data: response } = await axios.get<ApiResponse<Task[]>>(`/team-member/${data.userId}/tasks`);
            this.setTasks(response.data);
        },

        async addTaskAction(form: TaskForm): Promise<Task> {
            const { data } = await axios.post(this.endpoint, form);
            this.addTask(data.data);
            return data.data;
        },

        async updateTaskAction({ task, form }: TaskUpdatePayload): Promise<Task> {
            const { data } = await axios.patch<ApiResponse<Task>>(`${this.endpoint}/${task.id}`, form);
            this.updateTask(data.data);
            return data.data;
        },

        async removeTaskAction(task: Task): Promise<void> {
            await axios.delete(`${this.endpoint}/${task.id}`);
            this.removeTask(task);
        },

        async deleteTasks(): Promise<void> {
            await axios.delete(this.endpoint);
            const tasksToRemove = [...this.completedTasks];
            tasksToRemove.forEach(task => this.removeTask(task));
        }
    }
}); 