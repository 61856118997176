import { useColorMode, usePreferredDark } from '@vueuse/core'
import { computed, watch, ref, onMounted } from 'vue'
import { updateGlobalOptions, type ToastTheme, type ToastContainerOptions } from 'vue3-toastify'

// Import the default toast options from a shared location
// We'll create this file next
import { defaultToastOptions } from '@utils/toast-config'

// Define theme options
export type ThemeMode = 'auto' | 'light' | 'dark'

// Get system preference
const prefersDark = usePreferredDark()

// Create a color mode instance with proper configuration
export const colorMode = useColorMode({
    attribute: 'class',
    selector: 'html',
    initialValue: 'auto',
    storageKey: 'workweek-color-scheme',
    emitAuto: true, // Enable auto mode in the returned value
    modes: {
        // Use 'dark' class for dark mode (Tailwind default)
        dark: 'dark',
        // No class for light mode (Tailwind default)
        light: '',
        // For auto preference, we'll handle it in the logic
        auto: '',
    },
})

// Access the raw system and store values from useColorMode
const { system, store } = colorMode

// Helper to get the current theme mode
export const currentThemeMode = computed<ThemeMode>(() => {
    return store.value as ThemeMode
})

// Helper to check if dark mode is active
export const isDarkMode = computed<boolean>(() => {
    if (store.value === 'dark') {
        return true
    }

    if (store.value === 'auto') {
        return prefersDark.value
    }

    return false
})

// Apply the appropriate class based on the current theme
const applyThemeClass = (isDark: boolean): void => {
    if (isDark) {
        document.documentElement.classList.add('dark')
    } else {
        document.documentElement.classList.remove('dark')
    }
}

// Update toast theme based on current theme mode
const updateToastTheme = (isDark: boolean): void => {
    try {
        let toastTheme: ToastTheme

        if (store.value === 'auto') {
            toastTheme = 'auto'
        } else {
            toastTheme = isDark ? 'dark' : 'light'
        }

        // Use the default toast options to preserve settings like position
        updateGlobalOptions({
            ...defaultToastOptions,
            theme: toastTheme
        })
    } catch (error) {
        // Vue3Toastify might not be initialized yet
        console.debug('Toast theme update skipped:', error)
    }
}

// Watch for changes in the dark mode state and apply the appropriate class
watch(isDarkMode, (isDark) => {
    applyThemeClass(isDark)
    updateToastTheme(isDark)
}, { immediate: true })

// Watch for changes in the system preference when in auto mode
watch(prefersDark, (isDark) => {
    if (store.value === 'auto') {
        applyThemeClass(isDark)
        updateToastTheme(isDark)
    }
}, { immediate: true })

// Watch for changes in the theme mode
watch(store, (mode) => {
    if (mode === 'auto') {
        // If switching to auto mode, immediately apply the system preference
        applyThemeClass(prefersDark.value)
        updateToastTheme(prefersDark.value)
    } else {
        // Otherwise apply the selected mode
        applyThemeClass(mode === 'dark')
        updateToastTheme(mode === 'dark')
    }
}, { immediate: true })

// Function to set the theme mode
export function setThemeMode(mode: ThemeMode): void {
    // Directly set the store value
    store.value = mode

    // Force immediate DOM update
    if (mode === 'auto') {
        // When switching to auto, we need to manually apply the class
        // since useColorMode doesn't handle this automatically
        document.documentElement.className = prefersDark.value ? 'dark' : ''
        updateToastTheme(prefersDark.value)
    } else {
        // For light/dark, set the class directly
        document.documentElement.className = mode === 'dark' ? 'dark' : ''
        updateToastTheme(mode === 'dark')
    }
}

// Initialize theme based on stored preference or system default
export function initializeTheme(): void {
    const savedMode = localStorage.getItem('workweek-color-scheme') as ThemeMode | null

    if (savedMode) {
        // Set the store value directly
        store.value = savedMode
    } else {
        // Default to auto preference
        store.value = 'auto'
    }

    // Ensure the theme is applied correctly on initialization
    if (store.value === 'auto') {
        applyThemeClass(prefersDark.value)
        updateToastTheme(prefersDark.value)
    } else {
        applyThemeClass(store.value === 'dark')
        updateToastTheme(store.value === 'dark')
    }

    // Listen for system preference changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    mediaQuery.addEventListener('change', (e) => {
        if (store.value === 'auto') {
            applyThemeClass(e.matches)
            updateToastTheme(e.matches)
        }
    })
} 