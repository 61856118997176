import { type ToastContainerOptions } from 'vue3-toastify'

/**
 * Default configuration for Vue3Toastify
 * This ensures consistent toast behavior throughout the application
 */
export const defaultToastOptions: ToastContainerOptions = {
    position: "bottom-right",
    clearOnUrlChange: false,
    pauseOnFocusLoss: false,
    autoClose: 3000,
    dangerouslyHTMLString: false,
    transition: "bounce",
} 