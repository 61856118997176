import Popper from 'popper.js';
import type { PopperOptions, Placement, Data as PopperData, Modifiers } from 'popper.js';

type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left' | 'auto';
type TooltipTrigger = 'click' | 'hover' | 'focus' | 'manual' | null;

interface ExtendedPopperOptions extends PopperOptions {
    title?: string;
    htmlContent?: HTMLElement;
    offset?: number;
}

interface PopperInstance extends Popper {
    options: ExtendedPopperOptions;
}

interface TooltipModifier {
    element: string;
}

interface TooltipOffsetModifier {
    fn: (data: PopperData, options: PopperOptions) => PopperData;
}

interface TooltipModifiers extends Modifiers {
    arrow: TooltipModifier;
    offset?: TooltipOffsetModifier;
}

interface TooltipOptions {
    container: boolean;
    delay: number;
    instance: PopperInstance | null;
    fixIosSafari: boolean;
    eventsEnabled: boolean;
    html: boolean;
    modifiers: TooltipModifiers;
    placement: Placement;
    placementPostfix: string | null;
    removeOnDestroy: boolean;
    title: string;
    class: string;
    triggers: TooltipTrigger[];
    offset: number;
    onCreate?: (data: PopperData) => void;
    onUpdate?: (data: PopperData) => void;
}

interface TooltipDefaults extends Partial<TooltipOptions> {
    delay: number;
    offset: number;
    class: string;
}

const CSS: { HIDDEN: string; VISIBLE: string } = {
    HIDDEN: 'vue-tooltip-hidden',
    VISIBLE: 'vue-tooltip-visible'
};

const BASE_CLASS = `h-tooltip  ${CSS.HIDDEN}`;
const PLACEMENT: TooltipPlacement[] = ['top', 'left', 'right', 'bottom', 'auto'];
const SUB_PLACEMENT: string[] = ['start', 'end'];

enum EVENTS {
    ADD = 1,
    REMOVE = 2
}

const DEFAULT_OPTIONS: TooltipDefaults = {
    container: false,
    delay: 200,
    instance: null,
    fixIosSafari: false,
    eventsEnabled: false,
    html: false,
    modifiers: {
        arrow: {
            element: '.tooltip-arrow'
        }
    },
    placement: 'bottom',
    placementPostfix: null,
    removeOnDestroy: true,
    title: '',
    class: '',
    triggers: ['hover', 'focus'],
    offset: 5
};

const includes = <T>(stack: T[], needle: T): boolean => {
    return stack.indexOf(needle) > -1;
};

export default class Tooltip {
    private _options: TooltipOptions;
    private _$el: HTMLElement;
    private _$tpl: HTMLElement;
    private _$tt: PopperInstance;
    private _visible: boolean = false;
    private _disabled: boolean = false;
    private _clearDelay: number | null = null;
    private static _defaults: TooltipDefaults = { ...DEFAULT_OPTIONS };

    constructor(el: HTMLElement, options: Partial<TooltipOptions> = {}) {
        this._options = {
            ...Tooltip._defaults,
            onCreate: (data: PopperData) => {
                this.content(this.tooltip.options.title || '');
            },
            onUpdate: (data: PopperData) => {
                this.content(this.tooltip.options.title || '');
            },
            ...Tooltip.filterOptions(options)
        } as TooltipOptions;

        this._$el = el;
        this._$tpl = this._createTooltipElement(this._options);
        const defaultPlacement: Placement = 'bottom';
        this._$tt = new Popper(el, this._$tpl, {
            ...this._options,
            placement: this._options.placement || defaultPlacement
        }) as PopperInstance;
        this.setupPopper();
    }

    setupPopper(): void {
        this.disabled = false;
        this._visible = false;
        this._clearDelay = null;
        this._$tt.disableEventListeners();
        this._setEvents();
    }

    destroy(): void {
        this._cleanEvents();
        if (this._$tpl && this._$tpl.parentNode) {
            this._$tpl.parentNode.removeChild(this._$tpl);
        }
    }

    get options(): TooltipOptions {
        return { ...this._options };
    }

    get tooltip(): PopperInstance {
        return this._$tt;
    }

    get visible(): boolean {
        return this._visible;
    }

    set visible(val: boolean) {
        if (typeof val === 'boolean') {
            this._visible = val;
        }
    }

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(val: boolean) {
        if (typeof val === 'boolean') {
            this._disabled = val;
        }
    }

    show(): void {
        this.toggle(true);
    }

    hide(): void {
        this.toggle(false);
    }

    toggle(visible?: boolean, autoHide: boolean = true): void {
        let delay = this._options.delay;

        if (this.disabled === true) {
            visible = false;
            delay = 0;
        }

        if (typeof visible !== 'boolean') {
            visible = !this._visible;
        }

        if (visible === true) {
            delay = 0;
        }

        clearTimeout(this._clearDelay as number);

        if (autoHide === true) {
            this._clearDelay = window.setTimeout(() => {
                this.visible = visible as boolean;
                if (this.visible === true && this.disabled !== true) {
                    const body = document.querySelector('body');
                    if (body) {
                        body.appendChild(this._$tpl);
                    }

                    setTimeout(() => {
                        this._$tt.enableEventListeners();
                        this._$tt.scheduleUpdate();
                        this._$tpl.classList.replace(CSS.HIDDEN, CSS.VISIBLE);
                    }, 60);
                } else {
                    this._$tpl.classList.replace(CSS.VISIBLE, CSS.HIDDEN);
                    if (this._$tpl && this._$tpl.parentNode) {
                        this._$tpl.parentNode.removeChild(this._$tpl);
                    }

                    this._$tt.disableEventListeners();
                }
            }, delay);
        }
    }

    private _createTooltipElement(options: TooltipOptions): HTMLElement {
        const $popper = document.createElement('div');
        $popper.setAttribute('id', `tooltip-${randomId()}`);
        $popper.setAttribute('class', `${BASE_CLASS} ${this._options.class}`);

        const $arrow = document.createElement('div');
        $arrow.setAttribute('class', 'tooltip-arrow');
        $arrow.setAttribute('x-arrow', '');
        $popper.appendChild($arrow);

        const $content = document.createElement('div');
        $content.setAttribute('class', 'tooltip-content');
        $popper.appendChild($content);

        return $popper;
    }

    private _events(type: EVENTS = EVENTS.ADD): void {
        const evtType = (type === EVENTS.ADD) ? 'addEventListener' : 'removeEventListener';
        if (!Array.isArray(this.options.triggers)) {
            console.error('trigger should be an array', this.options.triggers);
            return;
        }

        const lis = (...params: [string, EventListener, boolean?]) => 
            this._$el[evtType](...params);

        if (includes(this.options.triggers, 'manual')) {
            lis('click', this._onToggle.bind(this), false);
        } else {
            if (this.options.fixIosSafari && Tooltip.isIosSafari() && includes(this.options.triggers, 'hover')) {
                const pos = this.options.triggers.indexOf('hover');
                const click = includes(this.options.triggers, 'click');
                this._options.triggers[pos] = click ? 'click' : null;
            }

            this.options.triggers.forEach(evt => {
                switch (evt) {
                    case 'click':
                        lis('click', (e: Event) => { this._onToggle(e); }, false);
                        break;
                    case 'hover':
                        lis('mouseenter', this._onActivate.bind(this), false);
                        lis('mouseleave', this._onDeactivate.bind(this), false);
                        break;
                    case 'focus':
                        lis('focus', this._onActivate.bind(this), false);
                        lis('blur', this._onDeactivate.bind(this), true);
                        break;
                }
            });

            if (includes(this.options.triggers, 'hover') || includes(this.options.triggers, 'focus')) {
                this._$tpl[evtType]('mouseenter', this._onMouseOverTooltip.bind(this), false);
                this._$tpl[evtType]('mouseleave', this._onMouseOutTooltip.bind(this), false);
            }
        }
    }

    private _setEvents(): void {
        this._events();
    }

    private _cleanEvents(): void {
        this._events(EVENTS.REMOVE);
    }

    private _onActivate(e: Event): void {
        this.show();
    }

    private _onDeactivate(e: Event): void {
        this.hide();
    }

    private _onToggle(e: Event): void {
        e.stopPropagation();
        e.preventDefault();
        this.toggle();
    }

    private _onMouseOverTooltip(e: Event): void {
        this.toggle(true, false);
    }

    private _onMouseOutTooltip(e: Event): void {
        this.toggle(false);
    }

    content(content: string | HTMLElement): void {
        const wrapper = this.tooltip.popper.querySelector('.tooltip-content');
        if (!wrapper) return;

        if (typeof content === 'string') {
            this.tooltip.options.title = content;
            wrapper.textContent = content;
        } else if (isElement(content)) {
            if (content !== wrapper.children[0]) {
                wrapper.innerHTML = '';
                this.tooltip.options.htmlContent = content;
                wrapper.appendChild(content);
            }
        } else {
            console.error('unsupported content type', content);
        }
    }

    set class(val: string) {
        if (typeof val === 'string') {
            const classList = this._$tpl.classList.value.replace(this.options.class, val);
            this._options.class = classList;
            this._$tpl.setAttribute('class', classList);
        }
    }

    static filterOptions(options: Partial<TooltipOptions>): Partial<TooltipOptions> {
        const opt: Partial<TooltipOptions> = { ...options };
        
        opt.modifiers = {
            arrow: {
                element: '.tooltip-arrow'
            }
        };

        const defaultPlacement: Placement = 'bottom';
        let placement: Placement = defaultPlacement;

        if (opt.placement && opt.placement.indexOf('-') > -1) {
            const [head, tail] = opt.placement.split('-');
            placement = (includes(PLACEMENT, head as TooltipPlacement) && includes(SUB_PLACEMENT, tail)) 
                ? (opt.placement as Placement)
                : (Tooltip._defaults.placement || defaultPlacement);
        } else if (opt.placement) {
            placement = (includes(PLACEMENT, opt.placement as TooltipPlacement)) 
                ? (opt.placement as Placement)
                : (Tooltip._defaults.placement || defaultPlacement);
        }

        opt.placement = placement;

        if (opt.modifiers) {
            opt.modifiers.offset = {
                fn: Tooltip._setOffset
            };
        }

        return opt;
    }

    static _setOffset(data: PopperData, opts: PopperOptions): PopperData {
        const instance = data.instance as PopperInstance;
        let offset = instance.options.offset ?? Tooltip._defaults.offset;

        if (window.isNaN(offset) || offset < 0) {
            offset = Tooltip._defaults.offset;
        }

        if (data.placement.indexOf('top') !== -1) {
            data.offsets.popper.top -= offset;
        } else if (data.placement.indexOf('right') !== -1) {
            data.offsets.popper.left += offset;
        } else if (data.placement.indexOf('bottom') !== -1) {
            data.offsets.popper.top += offset;
        } else if (data.placement.indexOf('left') !== -1) {
            data.offsets.popper.left -= offset;
        }

        return data;
    }

    static isIosSafari(): boolean {
        return includes(navigator.userAgent.toLowerCase().split(' '), 'mobile') && 
               includes(navigator.userAgent.toLowerCase().split(' '), 'safari') &&
               (navigator.platform.toLowerCase() === 'iphone' || 
                navigator.platform.toLowerCase() === 'ipad');
    }

    static defaults(data?: Partial<TooltipOptions>): TooltipDefaults {
        if (data) {
            Tooltip._defaults = { ...Tooltip._defaults, ...data };
        }
        return { ...Tooltip._defaults };
    }
}

function randomId(): string {
    return `${Date.now()}-${Math.round(Math.random() * 100000000)}`;
}

function isElement(value: unknown): value is HTMLElement {
    return value instanceof window.Element;
}
